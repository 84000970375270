import axios, { AxiosInstance } from 'axios'
import { Banner } from '@/interfaces/banner'

export default class BannerService {
  private BANNER_ENDPOINT = 'https://banners-api-dot-startmeup-218419.rj.r.appspot.com/banners'
  private client: AxiosInstance

  constructor() {
    this.client = axios
  }

  all() {
    return this.client
      .get(this.BANNER_ENDPOINT)
      .then((response) => response.data)
      .catch(() => [])
  }

  findById(id: string) {
    return this.client
      .get(`${this.BANNER_ENDPOINT}/${id}`)
      .then((response) => response.data)
  }

  create(data: Partial<Banner>) {
    return this.client
      .post(this.BANNER_ENDPOINT, data)
      .then((response) => response.data)
      .catch(() => null)
  }

  update(data: Banner) {
    return this.client
      .put(`${this.BANNER_ENDPOINT}/${data.id}/update`, data)
      .then((response) => response.data)
  }

  updateBannerOrder(bannerId: string, ordem: number) {
    return this.client
      .post(`${this.BANNER_ENDPOINT}/${bannerId}/ordem`, { ordem })
      .then((response) => response.data)
  }

  makeBannerPrivate(bannerId) {
    return this.client
      .post(`${this.BANNER_ENDPOINT}/${bannerId}/tornar-privado`)
      .then((response) => response.data)
  }

  makeBannerPublic(bannerId) {
    return this.client
      .post(`${this.BANNER_ENDPOINT}/${bannerId}/tornar-publico`)
      .then((response) => response.data)
  }

  activateBanner(bannerId) {
    return this.client
      .post(`${this.BANNER_ENDPOINT}/${bannerId}/ativar`)
      .then((response) => response.data)
  }

  deactivateBanner(bannerId) {
    return this.client
      .post(`${this.BANNER_ENDPOINT}/${bannerId}/desativar`)
      .then((response) => response.data)
  }
}
