






































































import Vue from 'vue'
import Component from 'vue-class-component'
import ConversionService from '@/services/Conversion.service'
import 'chart.js/dist/Chart.js'

import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes'
import { Angles6 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.office'

const Chart = (window as any).Chart

enum RangeOptions {
  LAST_SEVEN_DAYS = 'Últimos 7 dias',
  LAST_THIRTY_DAYS = 'Últimos 30 dias',
  CURRENT_MONTH = 'Este mês'
}

interface DateRange {
  from: string;
  to: string;
}

@Component({})
export default class ConversionStatistics extends Vue {
  private conversionService = new ConversionService();

  $moment;

  campaignChart: any = null

  campaigns: string[] = []

  RangeOptions = RangeOptions

  options: { selectedCampaigns: string[]; selectedRange: { value: string; label: string } } = {
    selectedCampaigns: [],
    selectedRange: null as any
  }

  labels: any[] = []

  datasets: any[] = []

  formState = {
    loading: false
  }

  async mounted() {
    this.initCampaignChart()

    this.campaigns = await this.conversionService.allCampaigns()
  }

  initCampaignChart() {
    const ctx = (this.$refs.campaignChart as any).getContext('2d')

    this.campaignChart = new Chart(ctx, {
      type: 'line',
      lineTension: 0,
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: {
        plugins: {
          colorschemes: {
            scheme: Angles6
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    })
  }

  async onFormSubmit() {
    this.formState.loading = true

    const { from, to } = this.convertRangeToDate(this.options.selectedRange)

    this.labels = this.buildLabels(from, to)
    this.datasets = await this.buildDatasets(from, to, this.options.selectedCampaigns)

    this.campaignChart.data.labels = this.labels
    this.campaignChart.data.datasets = this.datasets

    this.campaignChart.update()

    this.formState.loading = false
  }

  convertRangeToDate(selectedRange: { value: string; label: string }): DateRange {
    const range: DateRange = {} as DateRange

    switch (selectedRange.label) {
      case RangeOptions.LAST_SEVEN_DAYS:
        range.from = this.$moment().subtract(7, 'd').format('YYYY-MM-DD')
        range.to = this.$moment().format('YYYY-MM-DD')
        break
      case RangeOptions.LAST_THIRTY_DAYS:
        range.from = this.$moment().subtract(30, 'd').format('YYYY-MM-DD')
        range.to = this.$moment().format('YYYY-MM-DD')
        break
      case RangeOptions.CURRENT_MONTH:
        range.from = this.$moment().clone().startOf('month').format('YYYY-MM-DD')
        range.to = this.$moment().format('YYYY-MM-DD')
        break
    }

    return range
  }

  buildLabels(from, to) {
    from = this.$moment(from)
    to = this.$moment(to)

    const labels: string[] = []

    while (to.diff(from, 'days') >= 0) {
      labels.push(from.format('DD/MM/YYYY'))

      from.add(1, 'd')
    }

    return labels
  }

  async buildDatasets(from, to, selectedCampaigns) {
    const datasets: any[] = []

    for (let index = 0; index < selectedCampaigns.length; index++) {
      const campaign = selectedCampaigns[index]

      const query = {
        created_at: [`$gte.${from}`, `$lte.${to}`],
        utm_campaign: campaign
      }

      const conversions = await this.conversionService.allConversions(query)

      const dataset = this.formatDataset(campaign, from, to, conversions)

      datasets.push(dataset)
    }

    return datasets
  }

  formatDataset(campaign, from, to, conversions) {
    from = this.$moment(from)
    to = this.$moment(to)

    const data: number[] = []

    while (to.diff(from, 'days') >= 0) {
      const total = conversions.filter(c => {
        return (
          this.$moment(c.created_at).date() === from.date() &&
          this.$moment(c.created_at).month() === from.month() &&
          this.$moment(c.created_at).year() === from.year()
        )
      }).length

      data.push(total)

      from.add(1, 'd')
    }

    return {
      data,
      label: campaign,
      fill: false,
      pointRadius: 10,
      pointHoverRadius: 15
    }
  }
}
