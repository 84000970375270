import axios, { AxiosInstance } from 'axios'

export default class ImageService {
  private IMAGE_ENDPOINT = 'https://us-central1-startmeup-218419.cloudfunctions.net/image-service'
  private client: AxiosInstance

  constructor() {
    this.client = axios
  }

  uploadImage(file: File): Promise<string> {
    interface FormDataB extends FormData {
      _boundary: any;
    }

    const data: FormDataB = new FormData() as FormDataB

    data.append('photo', file)
    data.append('nome', file.name.replaceAll(' ', '-'))

    return this.client
      .post(this.IMAGE_ENDPOINT, data, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        }
      })
      .then((response) => {
        return response.data
      })
  }
}
