











































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import JsonExcel from 'vue-json-excel'
import { Banner } from '@/interfaces/banner'
import Model, { PageOptions, Pagination as IPagination } from '@/lib/prest-query-builder'
import PrestPagination from '@/components/PrestPagination.vue'
import UsersChart from '@/components/charts/UsersChart.vue'
import { Watch } from 'vue-property-decorator'
import BannerService from '@/services/Banner.service'

const { VUE_APP_API_PREST_NEW, VUE_APP_DASHBOARD_API } = process.env

@Component({
  components: {
    PrestPagination,
    JsonExcel,
    UsersChart
  }
})
export default class Users extends Vue {
  bannerModel!: Model<Banner>
  activeBannerModel!: Model<Banner>

  banners: Banner[] = []
  activeBanners: Banner[] = []

  pageOptions: PageOptions = {
    page: 1,
    pageSize: 20
  }

  selectedOrder = '-criado'

  state = {
    activeBannersHasChanged: false,
    loading: false,
    error: ''
  }

  pagination: IPagination = {} as IPagination

  $moment!: any

  get prestIsReady() {
    return !this.$store.getters['prest/loading'] && !!this.$store.getters['prest/authorizationKey']
  }

  get authorizationKey() {
    return this.$store.getters['prest/authorizationKey']
  }

  loadActiveBanners() {
    this.state.loading = true

    this.activeBannerModel
      .where({
        field: 'ativo',
        operator: '$eq',
        value: 'true'
      })
      .order('ordem')
      .run()
      .then((response: any) => {
        this.activeBanners = response
        this.state.loading = false
      })
  }

  loadBanners() {
    this.state.loading = true

    this.bannerModel
      .order(this.selectedOrder)
      .pagination(this.pageOptions)
      .run()
      .then((response: any) => {
        this.banners = response.items
        this.pagination = response.pagination
        this.state.loading = false
      })
  }

  onToPage(n) {
    this.$set(this.pageOptions, 'page', n)
    this.loadBanners()
  }

  updateActiveBannersOrder() {
    this.state.loading = true
    let promises: Promise<Banner>[] = []
    const bService = new BannerService()

    promises = this.activeBanners.map((b: Banner) => {
      return bService.updateBannerOrder(b.id as string, parseInt(b.ordem.toString()))
    })

    Promise.all(promises)
      .then(() => {
        this.loadActiveBanners()
        this.loadBanners()

        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: 'A ordem dos banners foi alterada com sucesso.',
          group: 'form'
        })
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar a ordem dos banners.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.state.loading = false
      })
  }

  @Watch('prestIsReady', { immediate: true })
  onPrestIsReadyChange(value) {
    if (value) {
      this.bannerModel = new Model<Banner>({
        url: `${VUE_APP_API_PREST_NEW}/Banner/public/banner`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      this.activeBannerModel = new Model<Banner>({
        url: `${VUE_APP_API_PREST_NEW}/Banner/public/banner`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      this.loadBanners()
      this.loadActiveBanners()
    }
  }

  makeBannerPublic(bannerId: string) {
    const response = window.confirm('Tem certeza que deseja tornar esse banner público?')

    if (!response) return

    const bService = new BannerService()

    return bService
      .makeBannerPublic(bannerId)
      .then(() => {
        this.loadBanners()
        this.loadActiveBanners()
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: 'O banner foi alterado para modo público.',
          group: 'form'
        })
      })
  }

  makeBannerPrivate(bannerId: string) {
    const response = window.confirm('Tem certeza que deseja tornar esse banner privado?')

    if (!response) return

    const bService = new BannerService()

    return bService
      .makeBannerPrivate(bannerId)
      .then(() => {
        this.loadBanners()
        this.loadActiveBanners()
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: 'O banner foi alterado para modo privado.',
          group: 'form'
        })
      })
  }

  activateBanner(bannerId: string) {
    const response = window.confirm('Tem certeza que deseja ativar esse banner?')

    if (!response) return

    const bService = new BannerService()

    return bService
      .activateBanner(bannerId)
      .then(() => {
        this.loadBanners()
        this.loadActiveBanners()
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: 'O banner foi ativado com sucesso.',
          group: 'form'
        })
      })
  }

  deactivateBanner(bannerId: string) {
    const response = window.confirm('Tem certeza que deseja desativar esse banner?')

    if (!response) return

    const bService = new BannerService()

    return bService
      .deactivateBanner(bannerId)
      .then(() => {
        this.loadBanners()
        this.loadActiveBanners()
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: 'O banner foi desativado com sucesso.',
          group: 'form'
        })
      })
  }

  onFilterSubmit() {
    this.$set(this.pageOptions, 'page', 1)
    this.loadBanners()
  }

  @Watch('activeBanners', { deep: true, immediate: false })
  onActiveBannersChange(to, from) {
    if (from.length > 0) {
      this.state.activeBannersHasChanged = true
    }
  }

  @Watch('pageOptions.pageSize')
  onPageOptionsChange() {
    this.$set(this.pageOptions, 'page', 1)
    this.loadBanners()
  }

  @Watch('selectedOrder')
  onOrderChange() {
    this.$set(this.pageOptions, 'page', 1)
    this.loadBanners()
  }
}

