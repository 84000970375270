
































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ConversionService from '@/services/Conversion.service'
import { Conversion, ConversionToCSV } from '@/interfaces/conversion'
import Pagination from '@/components/Pagination.vue'
import Statistics from '@/components/conversions/Statistics.vue'
import JsonExcel from 'vue-json-excel'
import { VueMaskDirective } from 'v-mask'

interface FilterOptions {
  email: string | null;
  action: string | null;
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  min_created_at: string |null;
  max_created_at: string | null;
}

@Component({
  components: {
    Pagination,
    Statistics,
    JsonExcel
  },
  directives: {
    mask: VueMaskDirective
  }
})
export default class ConversionsPage extends Vue {
  private conversionService = new ConversionService();

  $moment;

  ConversionToCSV = ConversionToCSV

  conversions: Conversion[] = []

  filter: FilterOptions = {
    email: null,
    action: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    min_created_at: null,
    max_created_at: null
  }

  options = {
    _page: 1,
    _page_size: 20,
    _order: '-created_at'
  }

  state = {
    downloadLoading: false,
    isFilterOpen: false,
    loading: false,
    error: ''
  }

  pagination = {
    ...this.options,
    totalDocs: 0,
    totalPages: 0
  }

  async mounted() {
    await this.loadConversions(this.options)
  }

  async loadConversions(opts = {}) {
    this.conversionService
      .allConversions(opts)
      .then((conversions: Conversion[]) => {
        this.conversions = conversions
      })
      .catch((error) => {
        console.log(error)
      })

    this.pagination.totalDocs = await this.conversionService.allConversionsCount(this.filter)
    this.pagination.totalPages = Math.ceil(this.pagination.totalDocs / this.pagination._page_size)
  }

  toPage(page: number) {
    this.$set(this.options, '_page', page)
    this.pagination = { ...this.pagination, ...this.options }
    this.loadConversions(this.options)
  }

  onFilterReset() {
    this.filter = {
      email: null,
      action: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      min_created_at: null,
      max_created_at: null
    }

    this.onFilterSubmit()
  }

  async onFilterSubmit() {
    const filter: any = { ...this.filter, created_at: [] }

    if (filter.min_created_at) {
      filter.created_at.push(`$gte.${this.$moment(filter.min_created_at, 'DD/MM/YYYY').format('YYYY-MM-DD')}`)
    }

    if (filter.max_created_at) {
      filter.created_at.push(`$lte.${this.$moment(filter.max_created_at, 'DD/MM/YYYY').format('YYYY-MM-DD')}`)
    }

    delete filter.min_created_at
    delete filter.max_created_at

    const opts = {
      _page: 1,
      _page_size: 20,
      ...filter
    }

    this.$set(this.options, '_page', 1)
    this.pagination = { ...this.pagination, ...this.options }

    this.conversions = await this.conversionService.allConversions(opts)
    this.pagination.totalDocs = await this.conversionService.allConversionsCount(filter)
    this.pagination.totalPages = Math.ceil(this.pagination.totalDocs / this.pagination._page_size)
  }

  fetchData() {
    return this.conversionService.allConversions(this.filter)
  }

  startDownload() {
    this.state.downloadLoading = true
  }

  finishDownload() {
    this.state.downloadLoading = false
  }
}

