



































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import ImageService from '@/services/Image.service'
import { Banner } from '@/interfaces/banner'
import BannerService from '@/services/Banner.service'

declare global {
  interface Window {
    Cropper: any;
  }
}

@Component({
  components: {
    VueCropper
  },
  directives: {}
})
export default class BannersPage extends Vue {
  assets: any[] = [
    {
      size: 'desktop',
      dataURL: null,
      file: null,
      cropped: false
    },
    {
      size: 'mobile',
      dataURL: null,
      file: null,
      cropped: false
    }
  ]

  form = {
    nome: '',
    privado: false,
    ativo: false,
    cta_url: '',
    mobile: '',
    desktop: ''
  } as Banner

  errors = {}

  /**
   * Main form state.
   */
  formState: { [key: string]: string | boolean } = {
    loading: false,
    showMobileForm: true,
    showDesktopForm: true
  }

  offerService = new OfferService()

  get desktop() {
    return this.assets.find(a => a.size === 'desktop')
  }

  get mobile() {
    return this.assets.find(a => a.size === 'mobile')
  }

  reset(form) {
    const index = this.assets.indexOf(form)
    this.$set(this.assets, index, { ...form, dataURL: null, file: null })
  }

  validate(file: File): { [key: string]: string } {
    const toMb = (kb: number) => parseFloat((kb / 1024 / 1024).toFixed(2))
    const errors: { [key: string]: string } = {}

    const size = toMb(file.size)

    if (size >= 1) {
      errors.size = 'Imagem deve ser menor que 1MB.'
    }

    return errors
  }

  onDesktopChange(e: Event) {
    const file = (e.target as any).files[0]

    if (!file) return

    const errors = this.validate(file)

    if (Object.keys(errors).length) {
      Object.keys(errors).forEach((e: string) => {
        alert(errors[e])
      })

      return
    }

    this.desktop.file = file

    const reader = new FileReader()

    const onLoadFile = (event: Event) => {
      const { result } = event.target as any

      this.$set(this.desktop, 'dataURL', result)
    }

    reader.addEventListener('load', onLoadFile.bind(this))

    reader.readAsDataURL(this.desktop.file)
  }

  async cropLogo(cropper: any) {
    const fileName = this.desktop.file.name

    this.desktop.file = await new Promise((resolve) =>
      cropper.getCroppedCanvas().toBlob(resolve, this.desktop.file.type)
    )

    this.desktop.file.name = fileName
    this.desktop.cropped = true

    const reader = new FileReader()

    reader.addEventListener(
      'load',
      (event: Event) => {
        const { result } = event.target as any
        this.$set(this.desktop, 'dataURL', result)
      }
    )

    reader.readAsDataURL(this.desktop.file)
  }

  async cropCover(cropper: any) {
    const fileName = this.mobile.file.name

    this.mobile.file = await new Promise((resolve) =>
      cropper.getCroppedCanvas().toBlob(resolve, this.mobile.file.type)
    )

    this.mobile.file.name = fileName
    this.mobile.cropped = true

    const reader = new FileReader()

    reader.addEventListener(
      'load',
      (event: Event) => {
        const { result } = event.target as any
        this.$set(this.mobile, 'dataURL', result)
      }
    )

    reader.readAsDataURL(this.mobile.file)
  }

  onMobileChange(e: Event) {
    const file = (e.target as any).files[0]

    if (!file) return

    const errors = this.validate(file)

    if (Object.keys(errors).length) {
      Object.keys(errors).forEach((e: string) => {
        alert(errors[e])
      })

      return
    }

    this.mobile.file = file

    const reader = new FileReader()

    const onLoadFile = (event: Event) => {
      const { result } = event.target as any

      this.$set(this.mobile, 'dataURL', result)
    }

    reader.addEventListener('load', onLoadFile.bind(this))

    reader.readAsDataURL(this.mobile.file)
  }

  async onFormSubmit() {
    this.formState.loading = true
    const imgService = new ImageService()

    const desktopURL = await imgService.uploadImage(this.desktop.file)
    const mobileURL = await imgService.uploadImage(this.mobile.file)

    if (!desktopURL || !mobileURL) {
      alert('Erro ao salvar imagens. Contate o suporte.')
      return
    }

    this.form.desktop = desktopURL
    this.form.mobile = mobileURL

    const bannerService = new BannerService()

    bannerService.create(this.form)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `O banner oferta ${response.nome} foi criado com sucesso.`,
          group: 'form'
        })
        this.$router.push('/banners')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao criar oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
      })
  }
}

