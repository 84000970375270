export interface Conversion {
  id: string;
  email: string;
  action: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
  created_at: Date;
}

export const ConversionToCSV = {
  Email: 'email',
  Ação: 'action',
  'Data de Criação': {
    field: 'created_at',
    callback: (value) => {
      const d = new Date(value)
      return d.toLocaleDateString('pt-BR')
    }
  },
  'UTM Source': 'utm_source',
  'UTM Medium': 'utm_medium',
  'UTM Campaign': 'utm_campaign',
  'UTM Term': 'utm_term',
  'UTM Content': 'utm_content'
}
