import { Conversion } from '@/interfaces/conversion'
import axios, { AxiosInstance, AxiosResponse } from 'axios'

export default class ConversionService {
  private DASHBOARD_ENDPOINT = process.env.VUE_APP_DASHBOARD_API;

  client: AxiosInstance;

  constructor(client: AxiosInstance = axios) {
    this.client = client
  }

  allConversions(options: any = {}): Promise<Conversion[]> {
    return this.client
      .get(`${this.DASHBOARD_ENDPOINT}/api/conversions`, { params: options })
      .then((response: AxiosResponse) => response.data)
  }

  allCampaigns() {
    return this.client
      .get(`${this.DASHBOARD_ENDPOINT}/api/conversions/campaigns`)
      .then((response: AxiosResponse) => response.data.map(c => c.utm_campaign))
  }

  allConversionsCount(options: any = {}): Promise<number> {
    return this.client
      .get(`${this.DASHBOARD_ENDPOINT}/api/conversions`, { params: { ...options, _count: '*' } })
      .then((response: AxiosResponse) => response.data[0].count)
  }

  saveConversion(form: Conversion): Promise<Conversion> {
    return this.client
      .post(`${this.DASHBOARD_ENDPOINT}/api/conversions`, { ...form })
      .then((response: AxiosResponse) => response.data)
  }
}
